import React, { useEffect } from 'react'
import { injectIntl } from '../../util/reactIntl';
import { useDispatch, useSelector } from 'react-redux'
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ManageSubscriptionPanel,
} from '../../components';
import css from './ManageSubscriptionPage.module.css';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { compose } from 'redux';
// import { checkScrollingDisabled } from './ManageSubscriptionPage.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { checkScrollingDisabled } from '../StripeSubscriptionPage/StripeSubscriptionPage.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
export const ManageSubscriptionPage = (props) => {
  const {
    intl,
    location,
    history,
    viewport
  } = props;
  // const {currentUserListing,typePartner} = useSelector(state=>state.user);
  const isScrollingDisabled = useSelector(state => checkScrollingDisabled(state));
  const title = intl.formatMessage({ id: 'ManageSubscriptionPage.title' });
  const diapatch = useDispatch();
  useEffect(() => {
    diapatch(fetchCurrentUser());
  }, [])
  return (
    <div>
      <Page className={css.root} title={title} scrollingDisabled={isScrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ManageSubscriptionPage" />
            {/* <UserNav selectedPageName="ManageSubscriptionPage" listing={currentUserListing} typePartner={typePartner}/> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>

              <ManageSubscriptionPanel history={history} location={location} />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>

    </div>
  )
}

export default compose(
  withViewport,
  withRouter,
  injectIntl
)(ManageSubscriptionPage)